import { getShowName } from '@/configs/domainConfig';
import { LoadingContainer } from '@/ui/loading';
import TypingText from '@/ui/typingText';
import { getQueryString } from '@/utils/helpers';
import React, { useEffect, useRef, useState } from 'react';
import ProductCard, { ProductProps } from './views/prodItem';
import { apiSearchShopPost } from '@/apis/searchShop';
import toast from '@/ui/toast/toast';

const ShopSearch: React.FC = () => {
  const [ask, setAsk] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [productData, setProductData] = useState<ProductProps[]>([]);
  const askInputRef = useRef<HTMLInputElement>(null);
  const replaceName = getShowName();

  const handleSearch = (userAsk: string) => {
    setIsSearching(true);
    apiSearchShopPost(userAsk)
      .then((res) => {
        const useData = res.map((item) => item.doc);
        setProductData(useData);
      })
      .catch((error) => {
        toast.error(String(error.message));
      })
      .finally(() => {
        setIsSearching(false);
      });
  };

  let firstLoad = true;

  useEffect(() => {
    if (firstLoad) {
      firstLoad = false;
      return;
    }
    const queryAsk = getQueryString('ask') ?? '';
    if (queryAsk) {
      setAsk(queryAsk);
      handleSearch(queryAsk);
    }
  }, []);

  return (
    <div className="flex min-h-screen flex-col bg-gray-700  px-12">
      <TypingText
        text={replaceName}
        speed={100}
        className="mx-0 my-8 text-center font-mono text-xl font-thin text-[#c8c8c8] md:mx-auto"
      />
      <div className="flex w-full items-center justify-center">
        <input
          type="text"
          id="askInput"
          placeholder="Search here"
          onChange={(e) => setAsk(e.target.value)}
          value={ask}
          ref={askInputRef}
          className="w-full rounded-l-md bg-gray-800 py-2 px-4 text-white outline-none"
        />
        <button
          className="rounded-r-md bg-blue-600 py-2 px-4"
          onClick={() => {
            handleSearch(ask);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-6 w-6"
          >
            <path
              fillRule="evenodd"
              d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div className="mt-6 rounded-[10px] bg-slate-800 p-8">
        {isSearching ? (
          <LoadingContainer />
        ) : productData.length > 0 ? (
          <div className="flex flex-col gap-8">
            {productData.map((productData, index) => (
              <div
                key={index}
                className="cursor-pointer"
                onClick={() => handleSearch(productData.description)}
              >
                <ProductCard
                  name={productData.name}
                  id={productData.id}
                  description={productData.description}
                  photo={productData.photo}
                  price={productData.price}
                  types={productData.types}
                  tags={productData.tags}
                />
              </div>
            ))}
          </div>
        ) : (
          'no product found'
        )}
      </div>
    </div>
  );
};

export default ShopSearch;
