import { useState } from 'react';
import { FaSignOutAlt } from 'react-icons/fa';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react';

function LogoutButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  function handleLogout() {
    setLoading(true);
    localStorage.clear(); // 清空本地缓存
    window.location.href = '/'; // 重定向到首页
  }

  return (
    <>
      {/* <IconButton
        icon={<FaSignOutAlt />}
        aria-label="Logout"
        onClick={onOpen}
      /> */}
      <FaSignOutAlt
        className="cursor-pointer"
        color="#000000"
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Logout</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to logout?</ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              isLoading={loading}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default LogoutButton;
