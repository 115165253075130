import request from '@/utils/request';
import { ProductProps } from '@/pages/shopSearch/views/prodItem';

type ResTypeSearchShop = {
  code: number;
  data: Array<{ doc: ProductProps; similarity: string }>;
  msg: string;
};

/**
 * @description 获取商品列表
 */
export const apiSearchShopPost = async (query: string) => {
  const res: ResTypeSearchShop = await request(
    `https://custom-ai.replit.app/azure_search`,
    {
      method: 'POST',
      body: {
        question: query,
        space_name: 'azure_hm',
        model_name: 'jp_saas'
      }
    }
  );
  return res.data;
};
