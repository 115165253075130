import CoinCompareChartWidget from '@/components/charts/CoinGecko';
import React from 'react';
import { useLocation } from 'react-router-dom';

const CoinGeckoPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const coinIds = queryParams.get('coinIds');
  const currency = queryParams.get('currency');

  return (
    <div className="h-full w-full bg-white">
      {coinIds && currency && (
        <CoinCompareChartWidget
          coinIds={coinIds}
          currency={currency}
          locale="en"
        />
      )}
    </div>
  );
};

export default CoinGeckoPage;
