export const domainConfig = {
  'scaffold-sui.movedid.build': {
    chatUrl: '/sui_chat',
    showName: 'Scaffold Sui based on AI'
  },
  'scaffold.movedid.build': {
    chatUrl: '/sui_chat',
    showName: 'Scaffold Sui based on AI'
  },
  'scaffold-move.com': {
    chatUrl: '/sui_chat',
    showName: 'Scaffold Sui based on AI'
  },
  'www.scaffold-move.com': {
    chatUrl: '/sui_chat',
    showName: 'Scaffold Sui based on AI'
  },
  'scaffold-aptos.movedid.build': {
    chatUrl: '/chatmodel/aptos',
    showName: 'Scaffold Aptos based on AI'
  },
  'chainintel.xyz': { chatUrl: '/web3chat', showName: 'Chainintel' },
  'www.chainintel.xyz': { chatUrl: '/web3chat', showName: 'Chainintel' },
  'ai.ahaclub.net': { chatUrl: '/chat', showName: 'AhaClub AI' },
  'aiis.life': { chatUrl: '/chat', showName: 'AIISLIFE' },
  'www.aiis.life': { chatUrl: '/chat', showName: 'AIIS.LIFE' },
  'dsearch.vercel.app': { chatUrl: '/chat', showName: 'DSearch' },
  'demo.doraemonai.ca': { chatUrl: '/chat', showName: 'Doraemon AI' }
};

// 获取显示名字的函数
export const getShowName = () => {
  const domain = window.location.hostname;
  const config = domainConfig[domain];
  if (config) {
    return config.showName;
  } else {
    return 'AIIS.LIFE'; // 或者你想要的默认名字
  }
};

// 获取聊天URL的函数
export const getChatUrl = () => {
  const domain = window.location.hostname;
  const config = domainConfig[domain];
  if (config) {
    return config.chatUrl;
  } else {
    return '/chatmodel/aptos'; // 或者你想要的默认URL
  }
};
