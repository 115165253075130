import React from 'react';

export type ProductProps = {
  name: string;
  id: number;
  description: string;
  photo: string;
  price: number;
  types: string;
  tags: string;
};

const ProductCard: React.FC<ProductProps> = ({
  name,
  id,
  description,
  photo,
  price,
  types,
  tags
}) => {
  return (
    <div className="flex flex-wrap  overflow-hidden rounded-xl bg-slate-300 shadow-lg sm:flex-nowrap">
      <img className="h-[200px] w-[200px]" src={photo} alt={name} />
      <div className="grow">
        <div className="px-6 py-4">
          <div className="mt-3 mb-2 text-xl font-bold">{name}</div>
          <p className="text-base text-gray-700">{description}</p>
        </div>
        <div className="px-6 pt-4 pb-2">
          <span className="mr-2 mb-2 inline-block rounded-full bg-gray-200 px-3 py-1 text-sm font-semibold text-gray-700">
            #{types}
          </span>
          <span className="mr-2 mb-2 inline-block rounded-full bg-gray-200 px-3 py-1 text-sm font-semibold text-gray-700">
            #{tags}
          </span>
          <span className="inline-block rounded-full bg-gray-200 px-3 py-1 text-sm font-semibold text-gray-700">
            ¥{price}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
