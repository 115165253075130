import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import parse from 'react-html-parser';

interface CoinCompareChartWidgetProps {
  coinIds: string;
  currency: string;
  locale: string;
}

const CoinCompareChartWidget: React.FC<CoinCompareChartWidgetProps> = ({
  coinIds,
  currency,
  locale
}) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://widgets.coingecko.com/coingecko-coin-compare-chart-widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const widgetHtml = `<coingecko-coin-compare-chart-widget coin-ids="${coinIds}" currency="${currency}" locale="${locale}"></coingecko-coin-compare-chart-widget>`;

  return (
    <>
      <Helmet>
        <script
          src="https://widgets.coingecko.com/coingecko-coin-compare-chart-widget.js"
          async
        />
      </Helmet>
      {parse(widgetHtml)}
    </>
  );
};

export default CoinCompareChartWidget;
