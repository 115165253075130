import Routes from '@/routes';
import { BrowserRouter } from 'react-router-dom';
import { AppSettingProvider } from '@/store/appSetting/context';
import { Toaster } from './ui/toast/toast';
import { ChakraProvider } from '@chakra-ui/react';
import { getShowName } from './configs/domainConfig';
import { useEffect } from 'react';

const App = (): JSX.Element => {
  useEffect(() => {
    document.title = getShowName(); // 使用 getShowName 函数来设置文档的标题
  }, []); // 只在组件挂载时运行
  return (
    <ChakraProvider>
      <BrowserRouter>
        <AppSettingProvider>
          <Routes />
          <Toaster />
        </AppSettingProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
};

export default App;
