import CoinCompareChartWidget from '@/components/charts/CoinGecko';
import LineChart from '@/components/charts/LineChart';
import TextCollapse from '@/ui/maxLineText';
import { FC } from 'react';

const data = [
  {
    DATE: '2023-04-24 00:00:00.000 UTC',
    Daily_Inscriptions: 14624,
    Daily_fees: 0.09941423000000217,
    Ord_Size_Usage: 12982396,
    Ord_vSize_Usage: 4676453,
    Total_Inscriptions: 1555971,
    Total_fees: 181.08763904000014
  },
  {
    DATE: '2023-04-23 00:00:00.000 UTC',
    Daily_Inscriptions: 193063,
    Daily_fees: 1.6385488300000468,
    Ord_Size_Usage: 91661579,
    Ord_vSize_Usage: 41416483,
    Total_Inscriptions: 1541347,
    Total_fees: 180.98822481000013
  },
  {
    DATE: '2023-04-22 00:00:00.000 UTC',
    Daily_Inscriptions: 99687,
    Daily_fees: 1.1878773799999796,
    Ord_Size_Usage: 92584184,
    Ord_vSize_Usage: 32022587,
    Total_Inscriptions: 1348284,
    Total_fees: 179.3496759800001
  },
  {
    DATE: '2023-04-21 00:00:00.000 UTC',
    Daily_Inscriptions: 55495,
    Daily_fees: 0.6464405399999973,
    Ord_Size_Usage: 72537599,
    Ord_vSize_Usage: 22239934,
    Total_Inscriptions: 1248597,
    Total_fees: 178.1617986000001
  },
  {
    DATE: '2023-04-20 00:00:00.000 UTC',
    Daily_Inscriptions: 8844,
    Daily_fees: 0.30556513000000135,
    Ord_Size_Usage: 48192610,
    Ord_vSize_Usage: 12729518,
    Total_Inscriptions: 1193102,
    Total_fees: 177.51535806000012
  },
  {
    DATE: '2023-04-19 00:00:00.000 UTC',
    Daily_Inscriptions: 11102,
    Daily_fees: 0.49039306999999904,
    Ord_Size_Usage: 64457164,
    Ord_vSize_Usage: 16965188,
    Total_Inscriptions: 1184258,
    Total_fees: 177.20979293000013
  },
  {
    DATE: '2023-04-18 00:00:00.000 UTC',
    Daily_Inscriptions: 15025,
    Daily_fees: 0.489187350000001,
    Ord_Size_Usage: 54755090,
    Ord_vSize_Usage: 14881803,
    Total_Inscriptions: 1173156,
    Total_fees: 176.71939986000012
  },
  {
    DATE: '2023-04-17 00:00:00.000 UTC',
    Daily_Inscriptions: 30622,
    Daily_fees: 0.8355751199999885,
    Ord_Size_Usage: 66687309,
    Ord_vSize_Usage: 19076358,
    Total_Inscriptions: 1158131,
    Total_fees: 176.23021251000011
  }
];

const data2 = [
  {
    bucket_order: 1,
    swap_bucket: '1-5',
    wallets: 1428560
  },
  {
    bucket_order: 2,
    swap_bucket: '6-10',
    wallets: 134408
  },
  {
    bucket_order: 3,
    swap_bucket: '11-25',
    wallets: 88193
  },
  {
    bucket_order: 4,
    swap_bucket: '26-99',
    wallets: 34501
  },
  {
    bucket_order: 5,
    swap_bucket: '100+',
    wallets: 3280
  }
];

const data3 = [
  {
    cumulative: 8123,
    day: '2015-08-01 00:00:00.000 UTC',
    new_users: 8123
  },
  {
    cumulative: 10529,
    day: '2015-09-01 00:00:00.000 UTC',
    new_users: 2406
  },
  {
    cumulative: 12689,
    day: '2015-10-01 00:00:00.000 UTC',
    new_users: 2160
  },
  {
    cumulative: 14711,
    day: '2015-11-01 00:00:00.000 UTC',
    new_users: 2022
  },
  {
    cumulative: 17431,
    day: '2015-12-01 00:00:00.000 UTC',
    new_users: 2720
  },
  {
    cumulative: 23929,
    day: '2016-01-01 00:00:00.000 UTC',
    new_users: 6498
  },
  {
    cumulative: 41233,
    day: '2016-02-01 00:00:00.000 UTC',
    new_users: 17304
  },
  {
    cumulative: 89029,
    day: '2016-03-01 00:00:00.000 UTC',
    new_users: 47796
  },
  {
    cumulative: 117973,
    day: '2016-04-01 00:00:00.000 UTC',
    new_users: 28944
  },
  {
    cumulative: 181951,
    day: '2016-05-01 00:00:00.000 UTC',
    new_users: 63978
  },
  {
    cumulative: 240710,
    day: '2016-06-01 00:00:00.000 UTC',
    new_users: 58759
  },
  {
    cumulative: 323195,
    day: '2016-07-01 00:00:00.000 UTC',
    new_users: 82485
  },
  {
    cumulative: 379943,
    day: '2016-08-01 00:00:00.000 UTC',
    new_users: 56748
  },
  {
    cumulative: 430520,
    day: '2016-09-01 00:00:00.000 UTC',
    new_users: 50577
  },
  {
    cumulative: 483179,
    day: '2016-10-01 00:00:00.000 UTC',
    new_users: 52659
  },
  {
    cumulative: 538800,
    day: '2016-11-01 00:00:00.000 UTC',
    new_users: 55621
  },
  {
    cumulative: 597232,
    day: '2016-12-01 00:00:00.000 UTC',
    new_users: 58432
  },
  {
    cumulative: 658998,
    day: '2017-01-01 00:00:00.000 UTC',
    new_users: 61766
  },
  {
    cumulative: 720275,
    day: '2017-02-01 00:00:00.000 UTC',
    new_users: 61277
  },
  {
    cumulative: 962999,
    day: '2017-03-01 00:00:00.000 UTC',
    new_users: 242724
  },
  {
    cumulative: 1199341,
    day: '2017-04-01 00:00:00.000 UTC',
    new_users: 236342
  },
  {
    cumulative: 1735319,
    day: '2017-05-01 00:00:00.000 UTC',
    new_users: 535978
  },
  {
    cumulative: 2818592,
    day: '2017-06-01 00:00:00.000 UTC',
    new_users: 1083273
  },
  {
    cumulative: 3719702,
    day: '2017-07-01 00:00:00.000 UTC',
    new_users: 901110
  },
  {
    cumulative: 5064223,
    day: '2017-08-01 00:00:00.000 UTC',
    new_users: 1344521
  },
  {
    cumulative: 6625138,
    day: '2017-09-01 00:00:00.000 UTC',
    new_users: 1560915
  },
  {
    cumulative: 8370274,
    day: '2017-10-01 00:00:00.000 UTC',
    new_users: 1745136
  },
  {
    cumulative: 10619444,
    day: '2017-11-01 00:00:00.000 UTC',
    new_users: 2249170
  },
  {
    cumulative: 15152191,
    day: '2017-12-01 00:00:00.000 UTC',
    new_users: 4532747
  },
  {
    cumulative: 21728307,
    day: '2018-01-01 00:00:00.000 UTC',
    new_users: 6576116
  },
  {
    cumulative: 25379277,
    day: '2018-02-01 00:00:00.000 UTC',
    new_users: 3650970
  },
  {
    cumulative: 28720775,
    day: '2018-03-01 00:00:00.000 UTC',
    new_users: 3341498
  },
  {
    cumulative: 31587995,
    day: '2018-04-01 00:00:00.000 UTC',
    new_users: 2867220
  },
  {
    cumulative: 35330148,
    day: '2018-05-01 00:00:00.000 UTC',
    new_users: 3742153
  },
  {
    cumulative: 38377219,
    day: '2018-06-01 00:00:00.000 UTC',
    new_users: 3047071
  },
  {
    cumulative: 40829930,
    day: '2018-07-01 00:00:00.000 UTC',
    new_users: 2452711
  },
  {
    cumulative: 43571867,
    day: '2018-08-01 00:00:00.000 UTC',
    new_users: 2741937
  },
  {
    cumulative: 45786672,
    day: '2018-09-01 00:00:00.000 UTC',
    new_users: 2214805
  },
  {
    cumulative: 48420891,
    day: '2018-10-01 00:00:00.000 UTC',
    new_users: 2634219
  },
  {
    cumulative: 50697566,
    day: '2018-11-01 00:00:00.000 UTC',
    new_users: 2276675
  },
  {
    cumulative: 53246461,
    day: '2018-12-01 00:00:00.000 UTC',
    new_users: 2548895
  },
  {
    cumulative: 55418102,
    day: '2019-01-01 00:00:00.000 UTC',
    new_users: 2171641
  },
  {
    cumulative: 56854906,
    day: '2019-02-01 00:00:00.000 UTC',
    new_users: 1436804
  },
  {
    cumulative: 59191910,
    day: '2019-03-01 00:00:00.000 UTC',
    new_users: 2337004
  },
  {
    cumulative: 61698078,
    day: '2019-04-01 00:00:00.000 UTC',
    new_users: 2506168
  },
  {
    cumulative: 65001473,
    day: '2019-05-01 00:00:00.000 UTC',
    new_users: 3303395
  },
  {
    cumulative: 68011749,
    day: '2019-06-01 00:00:00.000 UTC',
    new_users: 3010276
  },
  {
    cumulative: 70811857,
    day: '2019-07-01 00:00:00.000 UTC',
    new_users: 2800108
  },
  {
    cumulative: 73448912,
    day: '2019-08-01 00:00:00.000 UTC',
    new_users: 2637055
  },
  {
    cumulative: 75331439,
    day: '2019-09-01 00:00:00.000 UTC',
    new_users: 1882527
  },
  {
    cumulative: 77971629,
    day: '2019-10-01 00:00:00.000 UTC',
    new_users: 2640190
  },
  {
    cumulative: 80846077,
    day: '2019-11-01 00:00:00.000 UTC',
    new_users: 2874448
  },
  {
    cumulative: 83617638,
    day: '2019-12-01 00:00:00.000 UTC',
    new_users: 2771561
  },
  {
    cumulative: 85477077,
    day: '2020-01-01 00:00:00.000 UTC',
    new_users: 1859439
  },
  {
    cumulative: 87461117,
    day: '2020-02-01 00:00:00.000 UTC',
    new_users: 1984040
  },
  {
    cumulative: 89834763,
    day: '2020-03-01 00:00:00.000 UTC',
    new_users: 2373646
  },
  {
    cumulative: 93804660,
    day: '2020-04-01 00:00:00.000 UTC',
    new_users: 3969897
  },
  {
    cumulative: 97481424,
    day: '2020-05-01 00:00:00.000 UTC',
    new_users: 3676764
  },
  {
    cumulative: 100715666,
    day: '2020-06-01 00:00:00.000 UTC',
    new_users: 3234242
  },
  {
    cumulative: 104321476,
    day: '2020-07-01 00:00:00.000 UTC',
    new_users: 3605810
  },
  {
    cumulative: 107787777,
    day: '2020-08-01 00:00:00.000 UTC',
    new_users: 3466301
  },
  {
    cumulative: 110492775,
    day: '2020-09-01 00:00:00.000 UTC',
    new_users: 2704998
  },
  {
    cumulative: 113723678,
    day: '2020-10-01 00:00:00.000 UTC',
    new_users: 3230903
  },
  {
    cumulative: 117481359,
    day: '2020-11-01 00:00:00.000 UTC',
    new_users: 3757681
  },
  {
    cumulative: 121337948,
    day: '2020-12-01 00:00:00.000 UTC',
    new_users: 3856589
  },
  {
    cumulative: 125704045,
    day: '2021-01-01 00:00:00.000 UTC',
    new_users: 4366097
  },
  {
    cumulative: 130558687,
    day: '2021-02-01 00:00:00.000 UTC',
    new_users: 4854642
  },
  {
    cumulative: 136493860,
    day: '2021-03-01 00:00:00.000 UTC',
    new_users: 5935173
  },
  {
    cumulative: 144950175,
    day: '2021-04-01 00:00:00.000 UTC',
    new_users: 8456315
  },
  {
    cumulative: 155293477,
    day: '2021-05-01 00:00:00.000 UTC',
    new_users: 10343302
  },
  {
    cumulative: 163384118,
    day: '2021-06-01 00:00:00.000 UTC',
    new_users: 8090641
  },
  {
    cumulative: 170956093,
    day: '2021-07-01 00:00:00.000 UTC',
    new_users: 7571975
  },
  {
    cumulative: 180053244,
    day: '2021-08-01 00:00:00.000 UTC',
    new_users: 9097151
  },
  {
    cumulative: 190636432,
    day: '2021-09-01 00:00:00.000 UTC',
    new_users: 10583188
  },
  {
    cumulative: 202074252,
    day: '2021-10-01 00:00:00.000 UTC',
    new_users: 11437820
  },
  {
    cumulative: 223996641,
    day: '2021-11-01 00:00:00.000 UTC',
    new_users: 21922389
  },
  {
    cumulative: 236147392,
    day: '2021-12-01 00:00:00.000 UTC',
    new_users: 12150751
  },
  {
    cumulative: 248810464,
    day: '2022-01-01 00:00:00.000 UTC',
    new_users: 12663072
  },
  {
    cumulative: 258400650,
    day: '2022-02-01 00:00:00.000 UTC',
    new_users: 9590186
  },
  {
    cumulative: 270924728,
    day: '2022-03-01 00:00:00.000 UTC',
    new_users: 12524078
  },
  {
    cumulative: 282042116,
    day: '2022-04-01 00:00:00.000 UTC',
    new_users: 11117388
  },
  {
    cumulative: 293056605,
    day: '2022-05-01 00:00:00.000 UTC',
    new_users: 11014489
  },
  {
    cumulative: 302108345,
    day: '2022-06-01 00:00:00.000 UTC',
    new_users: 9051740
  },
  {
    cumulative: 314234670,
    day: '2022-07-01 00:00:00.000 UTC',
    new_users: 12126325
  },
  {
    cumulative: 325701113,
    day: '2022-08-01 00:00:00.000 UTC',
    new_users: 11466443
  },
  {
    cumulative: 339345993,
    day: '2022-09-01 00:00:00.000 UTC',
    new_users: 13644880
  },
  {
    cumulative: 362766983,
    day: '2022-10-01 00:00:00.000 UTC',
    new_users: 23420990
  },
  {
    cumulative: 394477961,
    day: '2022-11-01 00:00:00.000 UTC',
    new_users: 31710978
  },
  {
    cumulative: 427453822,
    day: '2022-12-01 00:00:00.000 UTC',
    new_users: 32975861
  },
  {
    cumulative: 455092467,
    day: '2023-01-01 00:00:00.000 UTC',
    new_users: 27638645
  },
  {
    cumulative: 481175488,
    day: '2023-02-01 00:00:00.000 UTC',
    new_users: 26083021
  },
  {
    cumulative: 512414135,
    day: '2023-03-01 00:00:00.000 UTC',
    new_users: 31238647
  },
  {
    cumulative: 534198221,
    day: '2023-04-01 00:00:00.000 UTC',
    new_users: 21784086
  }
];

const ChartView: FC = () => {
  return (
    // 通用图表组件
    // <div className="container mx-auto p-4">
    //   <LineChart data={data3} />
    // </div>
    // 滑动展开组件
    // <TextCollapse text={data3.map((d) => d.day).toString()} maxLines={3} />
    // coingecko 组件
    <CoinCompareChartWidget
      coinIds="bitcoin,ethereum,eos,ripple,litecoin"
      currency="usd"
      locale="en"
    />
  );
};

export default ChartView;
